import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Hero = ({ children }) => {
  return (
    <Wrapper>
      <StaticImage
        style={{ height: "100%", zIndex: "-1" }}
        objectFit="cover"
        objectPosition={"70% 50%"}
        placeholder="blur"
        loading="eager"
        alt="White modern kitchen"
        src="../../assets/images/hero-img.jpg"
        formats={["auto", "webp", "avif"]}
      />
      <div className="shadow"></div>
      <div className="content">{children}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 700px;
  position: relative;

  .shadow {
    height: 80%;
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: -0.5px;
    background-image: linear-gradient(
      to top,
      rgba(13, 13, 13, 1),
      rgba(13, 13, 13, 0)
    );
  }

  .content {
    height: fit-content;
    width: 90%;
    max-width: var(--max-width);
    margin-inline: auto;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
export default Hero
