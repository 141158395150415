import React from "react"
import styled from "styled-components"
import ImageBox from "./ImageBox"

const ImageButton = ({ data, alt, label, onClick }) => {
  return (
    <Wrapper onClick={() => onClick()}>
      <ImageBox data={data} alt={alt} eager={true} />
      <div className="shadow"></div>
      <h1 className="label">{label}</h1>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  text-align: center;
  cursor: pointer;
  transition-duration: 250ms;

  :hover > .label {
    transform: scale(1.02) translateX(2%);
    transition-duration: 250ms;
  }

  .shadow {
    position: absolute;
    bottom: 0;
    background-image: linear-gradient(
      to top,
      rgba(13, 13, 13, 0.5),
      rgba(13, 13, 13, 0)
    );
    width: 100%;
    height: 100%;
    border-radius: calc(var(--border-radius) - 1px);
    transition-duration: 200ms;
  }

  .label {
    font-weight: 300;
    height: fit-content;
    position: absolute;
    bottom: 1.2rem;
    left: 1rem;
    transition-duration: 250ms;
  }
`
export default ImageButton
