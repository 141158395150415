import React, { useState } from "react"
import styled from "styled-components"
import Section from "../common/Section"
import { StaticImage } from "gatsby-plugin-image"
const formatQuote = (name, quote) => {
  return { name, quote }
}

const quotes = [
  formatQuote(
    "Seth G.",
    "My kitchen is truly the focal point of my home.  Anyone who walks in is mesmerized and drawn to the kitchen.  Thank you for the true attention to detail."
  ),
  formatQuote(
    "Geri B.",
    "I am very impressed with all the jobs he has completed for me and my family members. They are a pleasure to deal with! The plans they supply are so creative and functional. Customer service is amazing! The products are high quality & workmanship and installation are to perfection. The best!!!!"
  ),
  formatQuote(
    "Abigail A.",
    "Adam and Avi are amazing people and very professionals at what they do. They really care and it shows! Their team is very good and attentive and was very accommodating. Everything that they promise - they deliver and it is very rare these days… highly recommended!"
  ),
]

const Testimonials = () => {
  const [index, setIndex] = useState(0)

  const stepQuote = step => {
    const nextIndex = index + step
    const maxIndex = quotes.length - 1

    if (nextIndex > maxIndex) {
      setIndex(0)
    } else if (nextIndex < 0) {
      setIndex(maxIndex)
    } else {
      setIndex(nextIndex)
    }
  }

  return (
    <Section>
      <Wrapper>
        <div className="top-section">
          <h1> Testimonials </h1>
          <div className="line" />

          <div className="buttons top-btns">
            <h1
              onClick={() => stepQuote(-1)}
              onKeyDown={() => stepQuote(-1)}
              role="presentation"
            >
              {"←"}
            </h1>
            <h1
              onClick={() => stepQuote(1)}
              onKeyDown={() => stepQuote(1)}
              role="presentation"
            >
              {"→"}
            </h1>
          </div>
        </div>

        <div className="quote-block">
          <StaticImage
            style={{ height: "100%", zIndex: "-1" }}
            objectFit="cover"
            objectPosition={"45% 50%"}
            placeholder="blur"
            alt="Quote mark"
            src="../../assets/icons/QuoteBlock.svg"
            formats={["auto", "webp", "avif"]}
          />
        </div>

        <div className="content">
          <p className="quote">{quotes[index].quote}</p>
          <p className="author">{quotes[index].name}</p>
        </div>

        <div className="bottom-section">
          <div className="line" />
          <div className="buttons">
            <h1
              onClick={() => stepQuote(-1)}
              onKeyDown={() => stepQuote(-1)}
              role="presentation"
            >
              {"←"}
            </h1>
            <h1
              onClick={() => stepQuote(1)}
              onKeyDown={() => stepQuote(1)}
              role="presentation"
            >
              {"→"}
            </h1>
          </div>
        </div>
      </Wrapper>
    </Section>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: -0.5rem;

  .top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .bottom-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
  }

  .top-section > .top-btns {
    display: none;
  }

  .bottom-section > .line,
  .top-section > .line {
    display: none;
    width: 100%;
    background-color: var(--secondary-color);
    height: 1px;
    margin-inline: 2rem;
  }
  .bottom-section > .line {
    display: block;
    margin-inline: 0rem;
    margin-right: 2rem;
  }
  .quote-block {
    opacity: 0.3;
    width: 20%;
  }

  .content {
    position: relative;
    margin-top: -30px;
    margin-inline: auto;
    width: 90%;
  }

  .quote {
    margin-bottom: 1rem;
  }

  p {
    font-weight: 200;
  }

  .author {
    font-weight: 600;
  }

  .buttons {
    display: flex;
    gap: 0.5rem;
    max-width: 170px;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .buttons > h1 {
    cursor: pointer;
  }

  @media screen and (min-width: 435px) {
    .top-section > .line {
      display: block;
    }

    .bottom-section > .line {
      display: none;
    }
    .top-section > .top-btns {
      display: flex;
    }

    .buttons {
      display: none;
    }
  }

  @media screen and (min-width: 425px) {
    .quote-block {
      width: 12%;
    }
  }
  @media screen and (min-width: 768px) {
    .content {
      width: 95%;
    }
    .quote-block {
      width: 10%;
    }
  }
`

export default Testimonials
