import React from "react"
import styled from "styled-components"
import Logo from "../../assets/icons/logo.svg"

const HeroContent = () => {
  return (
    <Wrapper>
      <div className="logo">
        <Logo />
      </div>
      <h2>Luxury Kitchens</h2>
      <h2>Over 58 Years of Experience</h2>
      <h2>Design & Installation </h2>
      <h2>Servicing Tri-state Area</h2>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;

  .logo {
    margin-left: -8px;
    margin-bottom: 1rem;
    width: fit-content;
  }
`

export default HeroContent
