import React from "react"
import styled from "styled-components"
import Section from "../common/Section"
import ImageButton from "../common/ImageButton"
import { navigate } from "gatsby"

import { graphql, useStaticQuery } from "gatsby"

const ChooseStyle = () => {
  const imagesData = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "buttons" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  `)

  const modern = imagesData.allFile.edges.find(n => n.node.name === "modern")
    .node.childImageSharp

  const classical = imagesData.allFile.edges.find(
    n => n.node.name === "classical"
  ).node.childImageSharp

  const transitional = imagesData.allFile.edges.find(
    n => n.node.name === "transitional"
  ).node.childImageSharp

  return (
    <Section>
      <Wrapper>
        <div className="title">
          <h1 id="touch">Tap your style</h1>
          <h1 id="click">Click your style</h1>
        </div>
        <div className="layout">
          <ImageButton
            data={modern}
            alt="Modern kitchen"
            label="Modern"
            onClick={() => navigate(`/gallery/modern`)}
          />
          <ImageButton
            data={transitional}
            alt="Transitional kitchen"
            label="Transitional"
            onClick={() => navigate(`/gallery/transitional`)}
          />

          <ImageButton
            data={classical}
            alt="Classical kitchen"
            label="Classical"
            onClick={() => navigate(`/gallery/classical`)}
          />

          <div className="filler" />
        </div>
      </Wrapper>
    </Section>
  )
}

const Wrapper = styled.div`
  margin-top: -3rem;

  #click {
    display: block;
  }
  #touch {
    display: none;
  }
  .title {
    margin-bottom: 2rem;
  }
  .layout {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .filler {
    display: none;
    background-color: #ffffff05;
    border-radius: var(--border-radius);
  }

  @media (hover: none) {
    #click {
      display: none;
    }
    #touch {
      display: block;
    }
  }

  @media screen and (min-width: 760px) {
    margin-top: -7rem;

    .filler {
      display: block;
    }
    .layout {
      gap: 4rem;
      display: grid;
      grid-template-columns: auto auto;
      grid-row: auto auto;
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
    }
  }
`

export default ChooseStyle
