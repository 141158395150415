import React from "react"
import Layout from "../components/common/Layout"
import ActionBar from "../components/actionBar/ActionBar"
import Hero from "../components/hero/Hero"
import HeroContent from "../components/hero/HeroContent"
import ChooseStyle from "../components/sections/ChooseStyle"
import Testimonials from "../components/sections/Testimonials"
import AboutUs from "../components/sections/AboutUs"
import Section from "../components/common/Section"
export default function Home() {
  return (
    <Layout>
      <ActionBar />
      <Hero>
        <HeroContent />
      </Hero>
      <ChooseStyle />
      <Section>
        <AboutUs />
      </Section>
      <Testimonials />
    </Layout>
  )
}

export function Head() {
  return (
    <>
      <title>The Kitchen Guy</title>
      <meta name="format-detection" content="telephone=no" />
    </>
  )
}
